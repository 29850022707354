import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar'
import Bar from '../components/Bar'
import SmSidebar from '../components/SmSidebar'
import UseWidth from '../custom-hooks/UseWidth.js'
import Header from '../components/Header.jsx'
import styles from './Layout.module.css'
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom'

const Layout = ({ children }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const { width } = UseWidth()
    const open = () => setOpenDrawer(!openDrawer)
    const { containerXXL } = styles
    const [searchParams] = useSearchParams();

    const { isAuthenticated, logout, getIdTokenClaims, isLoading } = useAuth0();
    const source = searchParams.get('source');

    // logging user out after 15 mins of inactivity
    // useEffect(() => {
    //     let timer;
    //     const resetTimer = () => {
    //         clearTimeout(timer);
    //         timer = setTimeout(() => {
    //             localStorage.clear();
    //             logout();
    //         },15 * 60 * 1000); 
    //     };

    //     if (isAuthenticated) {
    //         resetTimer();
    //         window.addEventListener('mousemove', resetTimer);
    //         window.addEventListener('keypress', resetTimer);
    //     }

    //     return () => {
    //         clearTimeout(timer);
    //         window.removeEventListener('mousemove', resetTimer);
    //         window.removeEventListener('keypress', resetTimer);
    //     };
    // }, [isLoading, isAuthenticated, logout, getIdTokenClaims, logout]);

     // Save the current time in localStorage before the tab is closed
     const saveLastTimeTabClosed = () => {
        const currentTime = new Date().toISOString();
        localStorage.setItem('lastTimeTabClosed', currentTime);
    };

    // Clear the lastTimeTabClosed key
    const clearLastTimeTabClosed = () => {
        localStorage.removeItem('lastTimeTabClosed');
    };

    // Check if the user should be logged out on tab reopen
    useEffect(() => {
        const checkLogout = () => {
            const lastTimeTabClosed = localStorage.getItem('lastTimeTabClosed');
            if (lastTimeTabClosed) {
                const lastTime = new Date(lastTimeTabClosed);
                const now = new Date();
                const timeDiff = now - lastTime; 

                // If more than 1 minute has passed 
                if (timeDiff > (60000)  && source!=='auth0') {
                    localStorage.clear();
                    logout({ logoutParams: { returnTo:process.env.REACT_APP_API_LOGOUT_URL } })
                }
            }
        };

        checkLogout();

        // Add event listeners to clear the key on user activity
        const handleUserActivity = () => {
            clearLastTimeTabClosed();
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keypress', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity); 
        window.addEventListener('touchmove', handleUserActivity);  
        window.addEventListener('touchend', handleUserActivity); 

        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keypress', handleUserActivity);
            window.removeEventListener('touchstart', handleUserActivity); 
            window.removeEventListener('touchmove', handleUserActivity);  
            window.removeEventListener('touchend', handleUserActivity); 
        };
    }, [logout]);

    // Set up the beforeunload event listener
    useEffect(() => {
        window.addEventListener('beforeunload', saveLastTimeTabClosed);

        return () => {
            window.removeEventListener('beforeunload', saveLastTimeTabClosed);
        };
    }, []);
    

    return (
        <Box>
            <div className={containerXXL}>
                <Header />
            </div>
            <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                }}
                    className={containerXXL}
                >
                    {children}
                </div>
            </Box>
            {
                width <= 768 && <SmSidebar openDrawer={openDrawer} onOpen={open} />
            }
        </Box>
    )
}

export default Layout
