import React, { useState, useEffect } from "react";
import {
    Box,
    FormControl,
    Button,
    Grid,
    Autocomplete,
    CircularProgress,
} from "@mui/material";
import { TextField, } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styles from "../../css/addpatient.module.css";
import "../../css/ClaimSearchStyles.css";
import { useNavigate } from "react-router-dom";
import { post_claimsstatusinquiry } from "../../services/ClaimService";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { get_GetAllPatientsDataAsync } from "../../services/addPatient";
import { get_NpiDataByNpiNumber } from "../../services/ClaimService";
import { get_GetPayerCodeByName } from "../../services/PayerService";
import dayjs from 'dayjs';
import { getLocalData } from "../../utils/localStorageFunctions";
import { defaultFormDetails, defaultUserDetails } from "../../utils/claimDefualtValues"
import { ymdDateFormat, utcFormat, equalDayjsDates } from "../../utils/DateFunctions"
import toast from "react-hot-toast";
import { getRequestNpi, smallLettersStr } from "../../utils/reusableFunctions";
import TableHeader from "../../components/TableHeader";
import { fetchPatientDetails, formDetailsCheck, handleScheduleTask, PayersDropdown, scheduleTask, searchButtonCheck } from "./ClaimFunctions";
import { enterAllReqFields, errFetchingDataStr, errorFetchingFieldsStr, taskScheduledFailedStr } from "../../utils/staticData";
import { get_Vendors } from "../../services/vendorSerice";

const ClaimSearch = () => {
    const navigate = useNavigate();
    const { formWrapper } = styles;
    const [payersData, setPayersData] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [maxDate] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() - 14);
        return date;
    });
    const currentNpi = getRequestNpi()
    const [formDetails, setformDetails] = useState({
        ...defaultFormDetails,
        NPI: currentNpi
    });
    const [isLoading, setIsLoading] = useState(false)
    const [patientSelected, setPatientSelected] = useState('')
    const [userDetails, setUserDetails] = useState(defaultUserDetails());
    const DropDownHandle = (event, newValue) => {
        setformDetails((prevDetails) => ({
            ...prevDetails,
            PatientInsurancePayer: newValue
        }));
    };
    const [vendors, setVendors] = useState([]);
    const [selectedVendors, setSelectedVendors] = useState([]);

    const handleChange = (event, value) => {
        if (event && event.target) {
            const { name, value: targetValue } = event.target;
            if (name === "PatientFirstName") {
                userDetails.subscriber.FirstName = targetValue
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    PatientFirstName: targetValue,
                }));
            }
            else if (name === "LastName") {
                userDetails.subscriber.lastName = targetValue;
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    LastName: targetValue,
                }));
            }
            else if (name === "NPI") {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    NPI: targetValue,
                }));
            }
            else if (name === "dateofservice") {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    dateofservice: ymdDateFormat(dayjs(targetValue)),
                }));
            }
            else {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: targetValue,
                }));
            }
        }
    };

    const searchbtn = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            if (formDetailsCheck(formDetails)) {
                toast.error(enterAllReqFields);
                return;
            }
            if (searchButtonCheck(selectedPatient, formDetails)) {
                try {
                    let npiInfo = getLocalData('selectedNpi');
                    const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
                    const specificData = patientdata?.data?.find(patient_record => {
                        return (smallLettersStr(formDetails.PatientFirstName) === smallLettersStr(patient_record?.firstName) &&
                            smallLettersStr(formDetails.LastName) === smallLettersStr(patient_record?.lastName))
                    }
                    ) || null;
                    if (specificData) {
                        formDetails.memberId = specificData?.memberId;
                        formDetails.dob = specificData?.dob;
                    }
                } catch (error) {
                    toast.error(errorFetchingFieldsStr);
                }
            }
            const npidatabynumber = await get_NpiDataByNpiNumber(formDetails?.NPI);
            const payerNameCodePair = {};
            const payerCodes1 = [];
            await Promise.all((formDetails?.PatientInsurancePayer || [])?.map(async (i) => {
                const payerInfo = await get_GetPayerCodeByName(i);
                if (payerInfo?.[0]) {
                    payerNameCodePair[payerInfo[0].payerName] = payerInfo[0]?.payerCode;
                    payerCodes1?.push(payerInfo[0]?.payerCode);
                }
            }));
            setformDetails((prevDetails) => ({
                ...prevDetails,
                payerCodes: payerCodes1 ?? [],
            }));
            const updatedDetails = {
                ...userDetails,
                payerCodes: payerCodes1 ?? [],
                ProviderLastName: npidatabynumber?.npiData?.providerLastName ?? '',
                ProviderNPI: formDetails?.NPI?.toString() ?? '',
                serviceStartDate: dayjs(formDetails?.dateofservice).subtract(5, 'day').format('MM/DD/YYYY'),
                serviceEndDate: dayjs(formDetails?.dateofservice).add(5, 'day').format('MM/DD/YYYY'),
                payerCode: payerCodes1[0] ?? '',
                subscriber: {
                    ...userDetails.subscriber,
                    memberID: formDetails?.memberId ?? '',
                    FirstName: formDetails?.PatientFirstName ?? '',
                    lastName: formDetails?.LastName ?? '',
                    dob: formDetails?.dob ?? '',
                },

            };
            setUserDetails(updatedDetails)
            let  postsearchdata = await post_claimsstatusinquiry(selectedVendors,updatedDetails);
            if (postsearchdata) {
                if (equalDayjsDates(formDetails?.scheduleDate, new Date())) {
                    navigate("/Claimresults", { state: { searchData: {...postsearchdata,...npidatabynumber,patientData:formDetails} } });
                } else if (formDetails?.scheduleDate?.isAfter(dayjs())) {
                    let npiInfo = getLocalData('selectedNpi');
                    const scheduleDetails = {
                        UserId: npiInfo?.id,
                        ScheduleType: 1,
                        ScheduleDate: utcFormat(formDetails?.scheduleDate),
                        ClaimsRequestParameters: {
                            ProviderFederalTaxId: userDetails?.ProviderFederalTaxId,
                            ProviderNPI: formDetails?.NPI?.toString() ?? '',
                            isPatientDependent: userDetails?.isPatientDependent,
                            DateofService: formDetails?.dateofservice
                        }
                    };
                    try {
                        await scheduleTask(formDetails, scheduleDetails, setSelectedPatient, setformDetails, defaultFormDetails, currentNpi)
                    } catch (error) {
                        toast.error(taskScheduledFailedStr);
                        console.error(error);
                    }
                }
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(errFetchingDataStr);
        }
    };

    useEffect(() => {
        PayersDropdown(setPayersData);
    }, []);

    useEffect(() => {
        if (patientSelected?.id) {
            fetchPatientDetails(patientSelected, setSelectedPatient, setformDetails, formDetails, currentNpi)
        }
        else {
            setUserDetails(defaultUserDetails())
            setformDetails({
                ...defaultFormDetails,
                NPI: currentNpi
            })
        }
    }, [patientSelected])
    useEffect(() => {
        const fetchVendors = async () => {
            try {
                const response = await get_Vendors()
                if (response.isSuccessful) {
                    setVendors(response.vendors);
                }
            } catch (error) {
                console.error('Error fetching vendors:', error);
            }
        };
        fetchVendors();
    }, []);


    return (
        <Box>
            <Box className="">
                <Box className="searchBox">
                    <FormControl fullWidth>
                        <TableHeader setFilterPatient={setPatientSelected} idNeeded />
                    </FormControl>
                </Box>
            </Box>
            <Box className="formContainer">
                <Box className={formWrapper}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <TextField
                                        label="First Name"
                                        variant="outlined"
                                        name="PatientFirstName"
                                        onChange={handleChange}
                                        color="error"
                                        required
                                        inputProps={{
                                            maxLength: 20,
                                            className: 'capitalizeInput'
                                        }}
                                        value={formDetails ? formDetails.PatientFirstName : ""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <TextField
                                        label="Last Name"
                                        variant="outlined"
                                        name="LastName"
                                        onChange={handleChange}
                                        color="error"
                                        required
                                        inputProps={{
                                            maxLength: 20,
                                            className: 'capitalizeInput'
                                        }}
                                        value={formDetails ? formDetails.LastName : ""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <Autocomplete
                                        multiple
                                        options={payersData}
                                        name="InsurancePayer"
                                        getOptionLabel={(option) => option}
                                        value={formDetails?.PatientInsurancePayer ? formDetails.PatientInsurancePayer : null}
                                        onChange={DropDownHandle}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Insurance Name"
                                                variant="outlined"
                                                color="success"
                                                helperText="Select insurance names"
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date of service"
                                            onChange={(val) => handleChange({ target: { name: 'dateofservice', value: dayjs(new Date(val).toISOString()) } })}
                                            name="dateofservice"
                                            maxDate={dayjs(maxDate)}

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    color="error"
                                                    required
                                                    inputProps={{ ...params.inputProps, maxLength: 20 }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Schedule Date"
                                            value={
                                                formDetails.scheduleDate
                                            }
                                            onChange={(newValue) => {
                                                handleScheduleTask(newValue, setformDetails);
                                            }}
                                            disablePast
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <TextField
                                        label="NPI"
                                        variant="outlined"
                                        name="NPI"
                                        color="primary"
                                        onChange={handleChange}
                                        value={formDetails?.NPI ?? currentNpi}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <Autocomplete
                                        multiple
                                        options={vendors}
                                        getOptionLabel={(option) => option.vendor_name}
                                        onChange={(event, newValue) => {
                                            // Store an array of selected vendors (both vendor_name and vendor_id)
                                            setSelectedVendors(
                                                newValue.map(vendor => ({
                                                    vendor_id: vendor.vendor_id,
                                                    vendor_name: vendor.vendor_name
                                                }))
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                color="error"
                                                required
                                                label="Select Vendors"
                                                placeholder="Vendors"
                                                inputProps={{ ...params.inputProps, maxLength: 20 }}
                                            />
                                        )}
                                    />

                                </FormControl>
                            </Grid>

                            <Grid container spacing={3} className="buttonContainer">
                                <Grid item>
                                    <Button label="Cancel" variant="outlined" color="inherit">
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        label="Search"
                                        variant="contained"
                                        color="success"
                                        onClick={searchbtn}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <CircularProgress size={23} color="inherit" />
                                        ) : (
                                            "Search"
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                           
                        </Grid>
                    </form>
                </Box>
            </Box>
        </Box>
    )
};
export default ClaimSearch;