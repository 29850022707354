import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  get_GetAllPatientsDataAsync,
} from "../../services/addPatient";
import { post_discoverysearchdata } from "../../services/registration";
import styles from "../../css/addpatient.module.css";
import dayjs from "dayjs";
import { getLocalData } from "../../utils/localStorageFunctions";
import toast from "react-hot-toast";
import "../../css/Discovery.css";
import { ymdDateFormat } from "../../utils/DateFunctions";
import { getRequestNpi } from "../../utils/reusableFunctions";
import { fetchSearchData, getDefaultNpiState, handleScheduleTask, handleSuggestionToShow } from "./DiscoveryFunctions";
import { unitedStates } from "../../utils/staticData";


const Search = () => {
  const navigate = useNavigate();
  const { formWrapper } = styles;
  const [suggestions, setSuggestions] = useState([]);
  const [input, setInput] = useState("");
  const [fieldsErr, setFieldsErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  var patientInsurancePayer = null;
  let npiInfo = getLocalData('selectedNpi');
  const [searchParams] = useSearchParams();
  const [useDateFromParam, setUseDateFromParam] = useState(false)

  const allParams = Object.fromEntries(searchParams.entries());
  const { firstName, lastName, dob } = allParams;

  const [userDetails, setUserDetails] = useState({
    patientLastName: "",
    patientFirstName: "",
    patientDOB: null,
    requestNpi: getRequestNpi(),
    phoneNumber: "",
    socialSecurityNumber: "",
    scheduleDate: ymdDateFormat(new Date()),
    email: "",
    appointmentDate: "",
    insurancename: "",
    memberId: 0,
    groupNumber: "",
    patientState: "",
    dos_startdate: dayjs().subtract(5, 'days'),
    dos_enddate: dayjs().add(5, 'days')
  });
  const [suggestionToShow, setSuggestionsToShow] = useState([])
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false)
  const [defaultNpiState, setDefaultNpiState] = useState('')

  useEffect(() => {
    getDefaultNpiState(setDefaultNpiState, setUserDetails, userDetails, firstName, lastName, dob, setUseDateFromParam)
  }, [])


  const handleSelectionChange = async (event, newValue) => {
    if (newValue === null) {
      setUserDetails({
        patientLastName: "",
        patientFirstName: "",
        patientDOB: null,
        requestNpi: 0,
        phoneNumber: "",
        socialSecurityNumber: "",
        email: "",
        appointmentDate: "",
        insurancename: "",
        memberId: 0,
        groupNumber: "",
        patientState: defaultNpiState,
        scheduleDate: userDetails?.scheduleDate ? userDetails?.scheduleDate : ymdDateFormat(new Date()),
        dos_startdate: dayjs().subtract(5, 'days'),
        dos_enddate: dayjs().add(5, 'days')
      });
      return;
    } else {
      const [namePart, dobPart] = newValue.split("-");
      const [firstName, lastName] = namePart.split(" ");
      const firstName1 = firstName;
      const lastName1 = lastName;
      const dobPart1 = dobPart;
      try {
        const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
        let specificData = null;
        for (let i = 0; i < patientdata?.data?.length; i++) {
          const patient_record = patientdata?.data[i];
          if (
            firstName1 === patient_record?.firstName &&
            lastName1 === patient_record?.lastName &&
            patient_record?.dob === dobPart1?.trim()
          ) {
            specificData = patient_record;
            break;
          }
        }
        let patient_user = {
          groupNumber: "",
          requestNpi: 0,
          patientDOB: "",
          patientId: "",
          scheduleDate: ymdDateFormat(new Date())
        };

        if (specificData?.id) {
          patient_user.patientId = specificData?.id;
        } else {
          patient_user.patientId = "";
        }
        if (specificData.firstName) {
          patient_user.patientFirstName = specificData.firstName;
        } else {
          patient_user.patientFirstName = "";
        }
        if (specificData.lastName) {
          patient_user.patientLastName = specificData.lastName;
        } else {
          patient_user.patientLastName = "";
        }
        if (specificData.dob) {
          patient_user.patientDOB = dayjs(specificData.dob).format('YYYY-MM-DD')
        } else {
          patient_user.patientDOB = "";
        }
        if (specificData.appointments) {
          patient_user.dateOfService = specificData.appointments[0];
        } else {
          patient_user.dateOfService = "";
        }
        if (specificData.email) {
          patient_user.email = specificData.email;
        } else {
          patient_user.email = "";
        }
        if (specificData.phone) {
          patient_user.phoneNumber = specificData.phone;
        } else {
          patient_user.phoneNumber = "";
        }
        if (specificData.patientInsuranceDetails) {
          if (typeof specificData.patientInsuranceDetails != "undefined") {
            patientInsurancePayer = specificData.patientInsuranceDetails[0];
            patient_user.insurancename = patientInsurancePayer;
          }
        } else {
          patient_user.insurancename = "";
        }
        if (specificData.ssn) {
          patient_user.socialSecurityNumber = specificData.ssn;
        } else {
          patient_user.socialSecurityNumber = "";
        }
        if (specificData.memberId) {
          patient_user.memberId = specificData.memberId;
        } else {
          patient_user.memberId = 0;
        }
        setUseDateFromParam(false)
        setUserDetails({
          ...patient_user,
          dos_startdate: dayjs().subtract(5, 'days'),
          dos_enddate: dayjs().add(5, 'days'),
          dateOfService: patient_user?.dateOfService ?? new Date(),
        });

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };



  const handleChange = (event, value) => {
    if (event && event.target) {
      const { name, value: targetValue } = event.target;
      setUserDetails((prevState) => ({
        ...prevState,
        [name]: targetValue,
      }));
      if (name === "FirstName") {
        setUserDetails((prevState) => ({
          ...prevState,
          patientFirstName: targetValue,
        }));
      } else if (name === "LastName") {
        setUserDetails((prevState) => ({
          ...prevState,
          patientLastName: targetValue,
        }));
      } else if (name === "ssn") {
        setUserDetails((prevState) => ({
          ...prevState,
          socialSecurityNumber: targetValue,
        }));
      }
    }
    setInput(value);
  };

  const searchbtn = async (e) => {
    e.preventDefault();
    if (
      !userDetails?.patientFirstName ||
      !userDetails?.patientLastName ||
      !userDetails?.patientDOB ||
      !userDetails?.patientState ||
      !userDetails?.scheduleDate
    ) {
      setFieldsErr(true);
      toast.error('Enter all required fields');
      return;
    } else setFieldsErr(false);
    const data = {
      patientLastName: userDetails?.patientLastName,
      patientFirstName: userDetails?.patientFirstName,
      patientDOB: userDetails?.patientDOB,
      requestNpi: getRequestNpi(),
      dos_startdate: dayjs(userDetails?.dos_startdate).format('YYYY-MM-DD'),
      dos_enddate: dayjs(userDetails?.dos_enddate).format('YYYY-MM-DD'),
      // patientSSN: userDetails?.socialSecurityNumber ?? "",
      // phoneNumber: userDetails?.phoneNumber ?? "",
      // policyHolderRelationship: userDetails?.policyHolderRelationship ?? "",
      // email: userDetails?.email ?? "",
      // insurancename: userDetails?.insurancename ?? "",
      // memberId: userDetails?.memberId?.toString() ?? "",
      // scheduleDate: userDetails?.scheduleDate ? utcFormat(userDetails.scheduleDate) : "",
      // groupNumber: userDetails?.groupNumber ?? "",
      // patientId: userDetails?.patientId ?? "",
      patientState: userDetails?.patientState ?? "",

      // ...(userDetails?.appointmentDate && {
      //   appointmentDate: userDetails.appointmentDate,
      // }),
    };
    try {
      setIsLoading(true);
      // if (
      //   data.scheduleDate &&
      //   dayjs(data.scheduleDate).isSame(dayjs(), "day")
      // ) {
      const postsearchdata = await post_discoverysearchdata(data);
      if (
        postsearchdata?.insuranceDiscoveryDto?.apiResponseMessage ===
        "Invalid patient state." ||
        postsearchdata?.insuranceDiscoveryDto?.apiResponseMessage ===
        "Subscriber/Insured Not Found \r\n"
      ) {
        toast.error("Invalid patient state.");
      } else {
        postsearchdata?.insuranceDiscoveryDto?.id &&
          navigate("/Searchresults?through=Discovery", {
            state: { searchData: postsearchdata },
          });
      }
      setIsLoading(false);
      // } 
      // else if (
      //   data.scheduleDate &&
      //   dayjs(data.scheduleDate).isAfter(dayjs(), "day")
      // ) {
      //   const scheduleDetails = {
      //     UserId: npiInfo?.id,
      //     ScheduleType: "InsuranceDiscovery",
      //     ScheduleDate: data.scheduleDate,
      //   };
      //   try {
      //     const response = await post_Scheduletask(data?.patientId, scheduleDetails);
      //     if (response?.isSuccessful) {
      //       toast.success("Task scheduled successfully");
      //     } else {
      //       toast.error("Failed to schedule task");
      //     }
      //   } catch (error) {
      //     toast.error("An error occurred while scheduling the task");
      //     console.error(error);
      //   } finally {

      //     setIsLoading(false);
      //   }

      // } else if (
      //   data.scheduleDate &&
      //   dayjs(data.scheduleDate).isBefore(dayjs(), "day")
      // ) {
      //   toast.error("The schedule date is in the past. Enter a correct date");
      //   setIsLoading(false);
      // }
    }
    catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSearchData(setSuggestions, setIsSuggestionsLoading, setInput)
  }, [])


  return (
    <Box>
      <Box>
        <Box className="autocompleteContainer">
          <FormControl fullWidth>
            <Autocomplete
              freeSolo
              options={suggestionToShow.map(
                (option) =>
                  `${option?.firstName} ${option?.lastName} - ${option?.dob}`
              )}
              value={input}
              onChange={handleSelectionChange}
              onInputChange={(e, val) => handleSuggestionToShow(e, val, setSuggestionsToShow, suggestions, setInput)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search Patients"
                  disabled={isSuggestionsLoading}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton aria-label="search">
                          {isSuggestionsLoading ? <CircularProgress color="success" size={20} /> : <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Box>
      </Box>
      <Box className="marginTopBox">
        <Box className={formWrapper}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className="formControl" fullWidth>
                  <TextField
                    label="First Name"
                    name="FirstName"
                    onChange={handleChange}
                    required
                    error={!userDetails?.patientFirstName && fieldsErr}
                    inputProps={{ maxLength: 20 }}
                    value={userDetails?.patientFirstName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className="formControl" fullWidth>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    name="LastName"
                    onChange={handleChange}
                    required
                    inputProps={{ maxLength: 20 }}
                    error={!userDetails?.patientLastName && fieldsErr}
                    value={userDetails?.patientLastName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                {/* <FormControl className="formControl" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="DOS From"
                      onChange={(newValue) => {
                        setUserDetails({
                          ...userDetails,
                          dos_startdate: dayjs(newValue)
                        })
                      }}
                      value={userDetails.dos_startdate}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl> */}
                <FormControl className="formControl" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Birth"
                      value={!userDetails?.patientDOB ? userDetails?.patientDOB : dayjs(userDetails?.patientDOB)}
                      onChange={(newValue) => {
                        setUserDetails({
                          ...userDetails,
                          patientDOB: dayjs(newValue),
                        });
                      }}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline ': {
                          borderColor: fieldsErr && !userDetails?.patientDOB ? '#d32f2f' : ''
                        },
                        '& .MuiFormLabel-root': {
                          color: fieldsErr && !userDetails?.patientDOB ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)' 
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="us-states-select-label"
                    error={!userDetails.patientState && fieldsErr}
                  >Patient State *</InputLabel>
                  <Select
                    labelId="us-states-select-label"
                    id="us-states-select"
                    value={userDetails?.patientState}
                    // readOnly={true}
                    label="Patient State *"
                    error={!userDetails.patientState && fieldsErr}
                    onChange={(e) => setUserDetails({ ...userDetails, patientState: e?.target?.value })}
                  >
                    {unitedStates?.map(({ name, code }) => (
                      <MenuItem key={code} value={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Social Security Number"
                    variant="outlined"
                    name="ssn"
                    onChange={handleChange}
                    inputProps={{ maxLength: 10 }}
                    value={userDetails.socialSecurityNumber}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6} lg={6}>
                <FormControl className="formControl" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      style={{
                        borderColor: !userDetails?.patientDOB && fieldsErr ? colors?.red : '',
                      }}
                      label="Date of Service"
                      variant="outlined"
                      name="dateofservice"
                      disablePast={true}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          dateOfService: new Date(e)?.toISOString(),
                        })
                      }
                      value={
                        userDetails.dateOfService
                          ? dayjs(userDetails.dateOfService)
                          : dayjs(new Date())
                      }
                      required
                      renderInput={(params) => (
                        <TextField {...params} required />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid> */}

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className="formControl" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Schedule Date"
                      onChange={(newValue) => {
                        handleScheduleTask(ymdDateFormat(dayjs(newValue)), setUserDetails);
                      }}
                      value={
                        userDetails.scheduleDate
                          ? dayjs(userDetails.scheduleDate)
                          : dayjs(new Date())
                      }
                      disablePast
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className="formControl" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="DOS From"
                      onChange={(newValue) => {
                        setUserDetails({
                          ...userDetails,
                          dos_startdate: dayjs(newValue)
                        })
                      }}
                      value={userDetails.dos_startdate}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className="formControl" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="DOS To"
                      onChange={(newValue) => {
                        setUserDetails({
                          ...userDetails,
                          dos_enddate: dayjs(newValue)
                        })
                      }}
                      value={userDetails?.dos_enddate}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid container spacing={3} className="buttonContainer">
                <Grid item>
                  <Button label="Cancel" variant="outlined" color="inherit">
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    label="Search"
                    variant="contained"
                    color="success"
                    onClick={searchbtn}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress size={23} color="inherit" />
                    ) : (
                      "Search"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Search;

