import { Box, Button, FormControl, Grid, InputAdornment, TextField } from "@mui/material";
import styles from '../../css/addpatient.module.css'
import { useEffect, useState } from "react";
import { getLocalData } from "../../utils/localStorageFunctions";
import { getUser } from "../../services/getRegisteredUser";
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import { updateUserDetails } from "../../services/updateUserDetails";
import toast from "react-hot-toast";
import { isNumber } from "../../utils/regexTestFunctions";
import { colors } from '../../utils/colors';
import { getRequestNpi } from "../../utils/reusableFunctions";
import { sharedStyles } from "../../sharedStyles";

const EditViewProfile = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [registeredUser, setRegisteredUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { formWrapperEditprofile, heading } = styles;
    const USER_ID = getLocalData('user')?.userId;

    const updateUser = async () => {
        const {
            userId,
            practiceState,
            practiceCity,
            practiceNpis,
            practiceAddress,
            jobTitle,
            firstName,
            lastName,
            phone,
            doctorNpis,
            email
        } = registeredUser?.userData;

        const payload = {
            firstName,
            lastName,
            userId,
            phone,
            jobTitle,
            practiceAddress,
            practiceCity,
            practiceState,
            doctorNpi: +(doctorNpis[0]) || null,
            practiceNpi: +([doctorNpis[0]]) || null,
        };

        if (payload) {
            setIsLoading(true)
            try {
                const response = await updateUserDetails(payload);
                if (response?.data?.isSuccessful) {
                    toast.success('User details updated successfully!');
                } else {
                    toast.error('Failed to update user details.');
                }
            } catch (error) {
                console.error(error);
                toast.error('An error occurred while updating user details.');
            } finally {
                fetchUserDetail();
            }
        }
    };

    const fetchUserDetail = async () => {
        setIsLoading(true);
        try {
            const response = await getUser(USER_ID);
            if (response?.data?.isSuccessful) {
                setRegisteredUser({ ...response?.data, userData: { ...response?.data?.data, doctorNpis: [getRequestNpi()] } });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUserDetail();
    }, [USER_ID]);

    return (
        <div style={{ width: '100%' }}>
            <Box className={formWrapperEditprofile}>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <TextField
                                    label="First Name"
                                    id="outlined-start-adornment"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline ': {
                                            borderRadius: '12px',
                                        },
                                        background: '#eee',
                                        borderRadius: '12px'
                                    }}
                                    disabled
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><LockIcon /></InputAdornment>,
                                    }}
                                    variant="outlined"
                                    value={isLoading ? '...Loading' : registeredUser?.userData?.firstName || ''}
                                    name="firstName"
                                    color="success"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <TextField
                                    label="Last Name"
                                    id="outlined-start-adornment"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline ': {
                                            borderRadius: '12px',
                                        },
                                        background: '#eee',
                                        borderRadius: '12px'
                                    }}
                                    disabled
                                    value={isLoading ? '...Loading' : registeredUser?.userData?.lastName || ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><LockIcon /></InputAdornment>,
                                    }}
                                    variant="outlined"
                                    name="lastName"
                                    color="success"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <TextField
                                    label="Email"
                                    id="outlined-start-adornment"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline ': {
                                            borderRadius: '12px',
                                        },
                                        background: '#eee',
                                        borderRadius: '12px'
                                    }}
                                    disabled
                                    value={isLoading ? '...Loading' : registeredUser?.userData?.email || ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{<LockIcon />}</InputAdornment>,
                                    }}
                                    variant="outlined"
                                    name="email"
                                    color="success"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <TextField
                                    label="#ID"
                                    id="outlined-start-adornment"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline ': {
                                            borderRadius: '12px',
                                        },
                                        background: '#eee',
                                        borderRadius: '12px'
                                    }}
                                    disabled
                                    value={isLoading ? '...Loading' : registeredUser?.userData?.id || ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                    variant="outlined"
                                    name="id"
                                    color="success"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <TextField
                                    label="Doctor NPIs"
                                    id="outlined-start-adornment"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline ': {
                                            borderRadius: '12px',
                                        },
                                        background: '#eee',
                                        borderRadius: '12px'
                                    }}
                                    disabled
                                    value={isLoading ? '...Loading' : registeredUser?.userData?.doctorNpis[0] || ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><LockIcon /></InputAdornment>,
                                    }}
                                    variant="outlined"
                                    name="doctorNpis"
                                    color="success"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <TextField
                                    label="Username"
                                    id="outlined-start-adornment"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline ': {
                                            borderRadius: '12px'
                                        },
                                        background: '#eee',
                                        borderRadius: '12px'
                                    }}
                                    disabled
                                    value={isLoading ? '...Loading' : registeredUser?.userData?.userName || ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{<LockIcon />}</InputAdornment>,
                                    }}
                                    variant="outlined"
                                    name="userName"
                                    color="success"
                                    onChange={(e) => setRegisteredUser((prevState) => ({
                                        ...prevState,
                                        userData: {
                                            ...prevState.userData,
                                            userName: e.target.value,
                                        },
                                    }))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <TextField
                                    label="Practice NPIs"
                                    id="outlined-start-adornment"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline ': {
                                            borderRadius: '12px',
                                        },
                                        background: '#eee',
                                        borderRadius: '12px'
                                    }}
                                    disabled
                                    value={isLoading ? '...Loading' : registeredUser?.userData?.practiceNpis?.[0] || ""}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                    variant="outlined"
                                    name="practiceNpis"
                                    color="success"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <TextField
                                    label="Phone Number"
                                    id="outlined-start-adornment"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline ': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    disabled={!isEditing}
                                    value={isLoading ? '...Loading' : registeredUser?.userData?.phone || ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                    onChange={(e) => setRegisteredUser((prevState) => ({
                                        ...prevState,
                                        userData: {
                                            ...prevState.userData,
                                            phone: e.target.value,
                                        },
                                    }))}
                                    variant="outlined"
                                    name="phone"
                                    color="success"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <TextField
                                    label="Practice Address"
                                    id="outlined-start-adornment"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline ': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    disabled={!isEditing}
                                    value={isLoading ? '...Loading' : registeredUser?.userData?.practiceAddress || ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                    variant="outlined"
                                    name="practiceAddress"
                                    color="success"
                                    onChange={(e) => setRegisteredUser((prevState) => ({
                                        ...prevState,
                                        userData: {
                                            ...prevState.userData,
                                            practiceAddress: e.target.value,
                                        },
                                    }))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <TextField
                                    label="Practice City"
                                    id="outlined-start-adornment"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline ': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    disabled={!isEditing}
                                    value={isLoading ? '...Loading' : registeredUser?.userData?.practiceCity || ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                    variant="outlined"
                                    name="practiceCity"
                                    color="success"
                                    onChange={(e) => setRegisteredUser((prevState) => ({
                                        ...prevState,
                                        userData: {
                                            ...prevState.userData,
                                            practiceCity: e.target.value,
                                        },
                                    }))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <TextField
                                    label="Practice State"
                                    id="outlined-start-adornment"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline ': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    disabled={!isEditing}
                                    value={isLoading ? '...Loading' : registeredUser?.userData?.practiceState || ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                    variant="outlined"
                                    name="practiceState"
                                    color="success"
                                    onChange={(e) => setRegisteredUser((prevState) => ({
                                        ...prevState,
                                        userData: {
                                            ...prevState.userData,
                                            practiceState: e.target.value,
                                        },
                                    }))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <TextField
                                    label="Job Title"
                                    id="outlined-start-adornment"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline ': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    disabled={!isEditing}
                                    value={isLoading ? '...Loading' : registeredUser?.userData?.jobTitle || ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                    variant="outlined"
                                    name="jobTitle"
                                    color="success"
                                    onChange={(e) => setRegisteredUser((prevState) => ({
                                        ...prevState,
                                        userData: {
                                            ...prevState.userData,
                                            jobTitle: e.target.value,
                                        },
                                    }))}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box sx={[sharedStyles?.displayFlex, sharedStyles?.gap2, sharedStyles?.justifyCenter, sharedStyles?.alignItemsCenter]}>
                        <Button
                            sx={[sharedStyles?.themeBtn, isEditing ? sharedStyles?.hide : sharedStyles?.displayFlex]}
                            onClick={() => setIsEditing(true)}
                            startIcon={<EditIcon />}
                            variant="contained"
                            color={"success"}
                            size="small"
                        >
                            Edit Details
                        </Button>
                        <Box sx={[sharedStyles?.displayFlex, sharedStyles?.gap2, sharedStyles?.justifyCenter, sharedStyles?.alignItemsCenter]}>
                            <Button
                                onClick={() => {
                                    setIsEditing(false);
                                    updateUser();
                                }}
                                disabled={isLoading}
                                sx={[isEditing? sharedStyles?.displayFlex: sharedStyles?.hide, sharedStyles?.themeBtn]}
                                variant="contained"
                                size="small"
                            >
                                Save Changes
                            </Button>
                            <Button
                                onClick={() => {
                                    setIsEditing(false);
                                    fetchUserDetail();
                                }}
                                disabled={isLoading}
                                sx={[isEditing ? sharedStyles?.displayFlex : sharedStyles?.hide, sharedStyles?.whiteBtn]}
                                variant="contained"
                                size="small"
                                color="error"
                            >
                                Cancel
                            </Button>

                        </Box>
                    </Box>
                </form>
            </Box>
        </div>
    );
};

export default EditViewProfile;
