import dayjs from "dayjs";
import { colors } from "./colors";
import { getCurrentMonthStr, getCurrMonth } from "./DateFunctions";
import { getLocalRequestNpi, trimmedStr } from "./reusableFunctions";
import { toast } from 'react-hot-toast';

export const unitedStates = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' }
];

export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const claimPaths = ['/ClaimTab', '/Claimdetails1', '/Claimresults']

export let staticData = [
  {
    NPI: 1285170167,
    Gender: "Male",
    LastUpdated: "2017-02-13",
    CertificationDate: "",
    EnumerationDate: "2017-01-16",
    NpiType: "NPI-1 Individual",
    SoleProprietor: "YES",
    Status: "Active",
    MailingAddress: {
      Street1: "5920 MCINTYRE ST",
      Street2: "",
      City: "GOLDEN",
      State: "CO",
      Country: "United States",
      Zip: "80403-7445",
      Phone: "303-949-1250",
      Fax: "303-225-4246"
    },
    PrimaryPracticeAddress: {
      Street1: "5920 MCINTYRE ST",
      Street2: "",
      City: "GOLDEN",
      State: "OH",
      Country: "United States",
      Zip: "80403-7445",
      Phone: "303-949-1250",
      Fax: "303-225-4246"
    },
    SecondaryPracticeAddresses: [],
    OtherIdentifiers: [
      {
        Issuer: "Other (non-Medicare)",
        State: "CO",
        Number: "RN.1617564"
      }
    ],
    Taxonomy: [
      {
        PrimayTaxonomy: "No",
        SelectedTaxonomy: "163W00000X - Registered Nurse",
        State: "CO",
        LicenseNumber: "RN.1617564"
      },
      {
        PrimayTaxonomy: "Yes",
        SelectedTaxonomy: "363LF0000X - Nurse Practitioner - Family",
        State: "CO",
        LicenseNumber: "APN.0992922-NP"
      }
    ],
    EntityTypeCode: null,
    EmployerIdentificationNumber: null,
    NPIDeactivationReasonCode: null,
    NPIDeactivationDate: null,
    NPIReactivationDate: null,
    ProviderOrganizationName: "JOHN FLAUDING NP",
    ProviderFirstName: "JOHN",
    ProviderLastName: "FLAUDING",
    HealthInformationExchange: []
  },
]

export let multipleData = [
  {
    NPI: 1285170167,
    Gender: "Male",
    LastUpdated: "2017-02-13",
    CertificationDate: "",
    EnumerationDate: "2017-01-16",
    NpiType: "NPI-1 Individual",
    SoleProprietor: "YES",
    Status: "Active",
    MailingAddress: {
      Street1: "5920 MCINTYRE ST",
      Street2: "",
      City: "GOLDEN",
      State: "CO",
      Country: "United States",
      Zip: "80403-7445",
      Phone: "303-949-1250",
      Fax: "303-225-4246"
    },
    PrimaryPracticeAddress: {
      Street1: "5920 MCINTYRE ST",
      Street2: "",
      City: "GOLDEN",
      State: "OH",
      Country: "United States",
      Zip: "80403-7445",
      Phone: "303-949-1250",
      Fax: "303-225-4246"
    },
    SecondaryPracticeAddresses: [],
    OtherIdentifiers: [
      {
        Issuer: "Other (non-Medicare)",
        State: "CO",
        Number: "RN.1617564"
      }
    ],
    Taxonomy: [
      {
        PrimayTaxonomy: "No",
        SelectedTaxonomy: "163W00000X - Registered Nurse",
        State: "CO",
        LicenseNumber: "RN.1617564"
      },
      {
        PrimayTaxonomy: "Yes",
        SelectedTaxonomy: "363LF0000X - Nurse Practitioner - Family",
        State: "CO",
        LicenseNumber: "APN.0992922-NP"
      }
    ],
    EntityTypeCode: null,
    EmployerIdentificationNumber: null,
    NPIDeactivationReasonCode: null,
    NPIDeactivationDate: null,
    NPIReactivationDate: null,
    ProviderOrganizationName: "JOHN FLAUDING NP",
    ProviderFirstName: "JOHN",
    ProviderLastName: "FLAUDING",
    HealthInformationExchange: []
  },
  {
    NPI: 1629638887,
    Gender: "Male",
    LastUpdated: "2023-07-27",
    CertificationDate: "2023-07-27",
    EnumerationDate: "2019-06-19",
    NpiType: "NPI-1 Individual",
    SoleProprietor: "NO",
    Status: "Active",
    MailingAddress: {
      Street1: "2925 CHICAGO AVE",
      Street2: "",
      City: "MINNEAPOLIS",
      State: "MN",
      Country: "United States",
      Zip: "55407-1321",
      Phone: "612-262-9000",
      Fax: "303-225-4246"
    },
    PrimaryPracticeAddress: {
      Street1: "7373 FRANCE AVE S",
      Street2: "",
      City: "EDINA",
      State: "MN",
      Country: "United States",
      Zip: "55435-4534",
      Phone: "952-835-1311",
      Fax: ""
    },
    SecondaryPracticeAddresses: [
      {
        Street1: "3650 JOSEPH SIEWICK DR",
        Street2: "STE 400",
        City: "FAIRFAX",
        State: "VA",
        Country: "United States",
        Zip: "22033-1715",
        Phone: "703-391-2020",
        Fax: ""
      },
      {
        Street1: "2030 MOUNTAIN VIEW AVE",
        Street2: "STE 300",
        City: "LONGMONT",
        State: "CO",
        Country: "United States",
        Zip: "80501-3181",
        Phone: "720-652-8860",
        Fax: "720-652-8861"
      }
    ],
    OtherIdentifiers: [],
    Taxonomy: [
      {
        PrimayTaxonomy: "No",
        SelectedTaxonomy: "207Q00000X - Family Medicine",
        State: "CO",
        LicenseNumber: "CDR.0002886"
      },
      {
        PrimayTaxonomy: "Yes",
        SelectedTaxonomy: "207Q00000X - Family Medicine",
        State: "VA",
        LicenseNumber: "0116032977"
      }
    ],
    EntityTypeCode: null,
    EmployerIdentificationNumber: null,
    NPIDeactivationReasonCode: null,
    NPIDeactivationDate: null,
    NPIReactivationDate: null,
    ProviderOrganizationName: "Dr. ANTHONY JIAN WU DO",
    ProviderFirstName: "ANTHONY",
    ProviderLastName: "WU",
    HealthInformationExchange: [
      {
        EndpointType: "Direct Messaging Address",
        Endpoint: "awu635781@soc.inova.org",
        EndpointDescription: "Health Information Exchange",
        Use: "Health Information Exchange (HIE)",
        ContentType: "CCDA",
        Affiliation: "Inova Physician Partners, LLC",
        EndpointLocation: "3650 Joseph Siewick Dr Ste 400 Fairfax, VA 22033-1715 United States"
      },
      {
        EndpointType: "Direct Messaging Address",
        Endpoint: "awu610208@excellian.direct.allina.com",
        EndpointDescription: "",
        Use: "Direct",
        ContentType: "CDA/PDF/TXT",
        Affiliation: "",
        EndpointLocation: "7373 France Ave S Edina, MN 55435-4534 United States"
      }
    ]
  },
  {
    NPI: 1831630680,
    Gender: "Male",
    LastUpdated: "2017-03-15",
    CertificationDate: "",
    EnumerationDate: "2017-03-13",
    NpiType: "NPI-1 Individual",
    SoleProprietor: "YES",
    Status: "Active",
    MailingAddress: {
      Street1: "5920 MCINTYRE ST",
      Street2: "",
      City: "GOLDEN",
      State: "CO",
      Country: "United States",
      Zip: "80403-7445",
      Phone: "720-434-4876",
      Fax: "303-225-4246"
    },
    PrimaryPracticeAddress: {
      Street1: "5920 MCINTYRE ST",
      Street2: "",
      City: "GOLDEN",
      State: "CO",
      Country: "United States",
      Zip: "80403-7445",
      Phone: "720-434-4876",
      Fax: ""
    },
    SecondaryPracticeAddresses: [],
    OtherIdentifiers: [],
    Taxonomy: [
      {
        PrimayTaxonomy: "Yes",
        SelectedTaxonomy: "363LF0000X - Nurse Practitioner - Family",
        State: "CO",
        LicenseNumber: "APN.0992972-NP"
      }
    ],
    EntityTypeCode: null,
    EmployerIdentificationNumber: null,
    NPIDeactivationReasonCode: null,
    NPIDeactivationDate: null,
    NPIReactivationDate: null,
    ProviderOrganizationName: "JAMES FLAUDING NP-C",
    ProviderFirstName: "JAMES",
    ProviderLastName: "FLAUDING",
    HealthInformationExchange: []
  },
  {
    NPI: 1285170167,
    Gender: "Male",
    LastUpdated: "2017-01-16",
    CertificationDate: "",
    EnumerationDate: "2017-01-16",
    NpiType: "NPI-1 Individual",
    SoleProprietor: "YES",
    Status: "Active",
    MailingAddress: {
      Street1: "5920 MCINTYRE ST",
      Street2: "",
      City: "GOLDEN",
      State: "CO",
      Country: "United States",
      Zip: "80403-7445",
      Phone: "303-949-1250",
      Fax: "303-225-4246"
    },
    PrimaryPracticeAddress: {
      Street1: "5920 MCINTYRE ST",
      Street2: "",
      City: "GOLDEN",
      State: "CO",
      Country: "United States",
      Zip: "80403-7445",
      Phone: "303-949-1250",
      Fax: "303-225-4246"
    },
    SecondaryPracticeAddresses: [],
    OtherIdentifiers: {
      Issuer: "Other (non-Medicare)",
      State: "CO",
      Number: "RN.1617564"
    },
    Taxonomy: [
      {
        PrimaryTaxonomy: "No",
        SelectedTaxonomy: "163W00000X - Registered Nurse",
        State: "CO",
        LicenseNumber: "RN.1617564"
      },
      {
        PrimaryTaxonomy: "Yes",
        SelectedTaxonomy: "363LF0000X - Nurse Practitioner - Family",
        State: "CO",
        LicenseNumber: "APN.0992922-NP"
      }
    ],
    EntityTypeCode: null,
    EmployerIdentificationNumber: null,
    NPIDeactivationReasonCode: null,
    NPIDeactivationDate: null,
    NPIReactivationDate: null,
    ProviderOrganizationName: "JOHN FLAUDING NP",
    ProviderFirstName: "JOHN",
    ProviderLastName: "FLAUDING",
    HealthInformationExchange: []
  },
  {
    NPI: 1134113285,
    Gender: "Male",
    LastUpdated: "2019-10-07",
    CertificationDate: "",
    EnumerationDate: "2005-09-06",
    NpiType: "NPI-1 Individual",
    SoleProprietor: "YES",
    Status: "Active",
    MailingAddress: {
      Street1: "9001 DIGGES RD",
      Street2: "STE 105",
      City: "MANASSAS",
      State: "VA",
      Country: "United States",
      Zip: "20110-4414",
      Phone: "703-369-5000",
      Fax: "703-369-5003"
    },
    PrimaryPracticeAddress: {
      Street1: "9001 DIGGES RD",
      Street2: "SUITE 104",
      City: "MANASSAS",
      State: "VA",
      Country: "United States",
      Zip: "20110-4421",
      Phone: "703-369-5000",
      Fax: "703-369-5003"
    },
    SecondaryPracticeAddresses: [],
    OtherIdentifiers: [
      {
        Issuer: "MEDICAID",
        State: "VA",
        Number: "05622832"
      },
      {
        Issuer: "Other (non-Medicare)",
        State: "",
        Number: "080175697"
      }
    ],
    Taxonomy: [
      {
        PrimayTaxonomy: "Yes",
        SelectedTaxonomy: "207Q00000X - Family Medicine",
        State: "VA",
        LicenseNumber: "0101054208"
      }
    ],
    EntityTypeCode: null,
    EmployerIdentificationNumber: null,
    NPIDeactivationReasonCode: null,
    NPIDeactivationDate: null,
    NPIReactivationDate: null,
    ProviderOrganizationName: "PARIMAL C DESAI MD",
    ProviderFirstName: "PARIMAL",
    ProviderLastName: "DESAI",
    HealthInformationExchange: []
  },
  {
    NPI: 1780236646,
    Gender: "Male",
    LastUpdated: "2021-07-13",
    CertificationDate: "2021-07-13",
    EnumerationDate: "2019-07-11",
    NpiType: "NPI-1 Individual",
    SoleProprietor: "NO",
    Status: "Active",
    MailingAddress: {
      Street1: "6121 N THESTA ST",
      Street2: "",
      City: "FRESNO",
      State: "CA",
      Country: "United States",
      Zip: "93710-8603",
      Phone: "559-538-1727",
      Fax: ""
    },
    PrimaryPracticeAddress: {
      Street1: "6121 N THESTA ST",
      Street2: "",
      City: "FRESNO",
      State: "CA",
      Country: "United States",
      Zip: "93710-8603",
      Phone: "559-538-1727",
      Fax: ""
    },
    SecondaryPracticeAddresses: [],
    OtherIdentifiers: [],
    Taxonomy: [
      {
        PrimayTaxonomy: "Yes",
        SelectedTaxonomy: "225800000X - Recreation Therapist",
        State: "",
        LicenseNumber: ""
      }
    ],
    EntityTypeCode: null,
    EmployerIdentificationNumber: null,
    NPIDeactivationReasonCode: null,
    NPIDeactivationDate: null,
    NPIReactivationDate: null,
    ProviderOrganizationName: "SALVADOR GONZALES III",
    ProviderFirstName: "SALVADOR",
    ProviderLastName: "GONZALES",
    HealthInformationExchange: []
  },
  {
    NPI: 1992723381,
    Gender: "Male",
    LastUpdated: "2008-02-21",
    CertificationDate: "",
    EnumerationDate: "2006-07-17",
    NpiType: "NPI-1 Individual",
    SoleProprietor: "YES",
    Status: "Active",
    MailingAddress: {
      Street1: "4770 W HERNDON AVE",
      Street2: "",
      City: "FRESNO",
      State: "CA",
      Country: "United States",
      Zip: "93722-8401",
      Phone: "559-271-6300",
      Fax: "559-271-6325"
    },
    PrimaryPracticeAddress: {
      Street1: "4770 W HERNDON AVE",
      Street2: "",
      City: "FRESNO",
      State: "CA",
      Country: "United States",
      Zip: "93722-8401",
      Phone: "559-271-6300",
      Fax: "559-271-6325"
    },
    SecondaryPracticeAddresses: [],
    OtherIdentifiers: [
      {
        Issuer: "Other (non-Medicare)",
        State: "",
        Number: "P00361232"
      }
    ],
    Taxonomy: [
      {
        PrimayTaxonomy: "Yes",
        SelectedTaxonomy: "207Q00000X - Family Medicine",
        State: "CA",
        LicenseNumber: "G70976"
      }
    ],
    EntityTypeCode: null,
    EmployerIdentificationNumber: null,
    NPIDeactivationReasonCode: null,
    NPIDeactivationDate: null,
    NPIReactivationDate: null,
    ProviderOrganizationName: "MATTHEW LOZANO MD",
    ProviderFirstName: "MATTHEW",
    ProviderLastName: "LOZANO",
    HealthInformationExchange: []
  },
  {
    NPI: 1447247820,
    Gender: "Female",
    LastUpdated: "2015-11-09",
    CertificationDate: "",
    EnumerationDate: "2005-09-30",
    NpiType: "NPI-1 Individual",
    SoleProprietor: "NO",
    Status: "Active",
    MailingAddress: {
      Street1: "4770 W HERNDON AVE",
      Street2: "",
      City: "FRESNO",
      State: "CA",
      Country: "United States",
      Zip: "93722-8401",
      Phone: "559-271-6365",
      Fax: "559-271-6326"
    },
    PrimaryPracticeAddress: {
      Street1: "4770 W HERNDON AVE",
      Street2: "",
      City: "FRESNO",
      State: "CA",
      Country: "United States",
      Zip: "93722-8401",
      Phone: "559-271-6365",
      Fax: "559-271-6326"
    },
    SecondaryPracticeAddresses: [],
    OtherIdentifiers: [],
    Taxonomy: [
      {
        PrimayTaxonomy: "Yes",
        SelectedTaxonomy: "363AM0700X - Physician Assistant - Medical",
        State: "CA",
        LicenseNumber: "PA17687"
      }
    ],
    EntityTypeCode: null,
    EmployerIdentificationNumber: null,
    NPIDeactivationReasonCode: null,
    NPIDeactivationDate: null,
    NPIReactivationDate: null,
    ProviderOrganizationName: "MIRIAM LEVITT PAC",
    ProviderFirstName: "MIRIAM",
    ProviderLastName: "LEVITT",
    HealthInformationExchange: []
  },
  {
    NPI: 1376566968,
    Gender: "Female",
    LastUpdated: "2008-01-30",
    CertificationDate: "",
    EnumerationDate: "2006-07-25",
    NpiType: "NPI-1 Individual",
    SoleProprietor: "NO",
    Status: "Active",
    MailingAddress: {
      Street1: "540 E HERNDON AVE STE 103",
      Street2: "",
      City: "FRESNO",
      State: "CA",
      Country: "United States",
      Zip: "93720-2907",
      Phone: "559-432-8300",
      Fax: "559-432-9083"
    },
    PrimaryPracticeAddress: {
      Street1: "540 E HERNDON AVE STE 106",
      Street2: "",
      City: "FRESNO",
      State: "CA",
      Country: "United States",
      Zip: "93720-2907",
      Phone: "559-432-8300",
      Fax: "559-432-9083"
    },
    SecondaryPracticeAddresses: [],
    OtherIdentifiers: [],
    Taxonomy: [
      {
        PrimayTaxonomy: "Yes",
        SelectedTaxonomy: "363A00000X - Physician Assistant",
        State: "CA",
        LicenseNumber: "PA17987"
      }
    ],
    EntityTypeCode: null,
    EmployerIdentificationNumber: null,
    NPIDeactivationReasonCode: null,
    NPIDeactivationDate: null,
    NPIReactivationDate: null,
    ProviderOrganizationName: "SANDRA L. LEE PA",
    ProviderFirstName: "SANDRA",
    ProviderLastName: "LEE",
    HealthInformationExchange: []
  }
]

export const payerDefault = {
  insuranceCompany: "",
  postalCode: "",
  state: '',
  city: ""
}

export const npiDefault = {
  providerFirstName: "",
  providerLastName: "",
  npi: null,
  postalCode: "",
  state: '',
  city: ""
};


// Select Menu as Policy Holder Relationship
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
    },
  },
};



export const unReadNotifications = "Unread Notifications";
export const unReadNotificationsMessage = "You have no unread notifications!"
export const staticNotifications = [{ notification: 'Patient Successfully added', time: 'Just a few minutes ago', description: 'Patient with name of John Doe was added, confirm further appointments.' }, { notification: 'New npi was added', time: '5 minutes ago', description: 'NPI was added with name of Aeron.' }, { notification: 'Upcoming appointment', time: '20 minutes ago', description: 'Pending appointment with for patient xyz.' }]
export const notificationRoute = 'notifications'
export const policyHolderRelationship = ['Self', 'Spouse', 'Child'];
export const discoveryPaths = ['/Discoverytab', '/Discoverydetails1'];
export const eligibilityPaths = ['/EligibilityTab', '/Eligibilitydetails1', '/EligibilitySearchResults','/claimEligibilityDetails']
export const patientOverviewHeadings = ['New Patients Added', 'Patients having insurance', 'Patients missing insurance']
export const patientsClaimsHeadings = ['Number of paid claims', 'Value of paid claims', 'Number of denied claims', 'Value of denied claims']
export const patientsClaimsFilters = [`today`, '1 week', '1 month', '6 months', '1 year']

export const patientsOverviewGraphData = {
  labels: [],
  datasets: [
    {
      label: 'New Patients Added',
      data: [],
      backgroundColor: colors?.lightGreen,
      borderColor: colors?.themeGreen,
      borderWidth: 1
    },
    {
      label: 'Patients having insurance',
      data: [],
      backgroundColor: colors?.lightYellow,
      borderColor: colors?.yellow,
      borderWidth: 1
    },
    {
      label: 'Patients missing insurance',
      data: [],
      backgroundColor: colors?.lightRed,
      borderColor: colors?.danger,
      borderWidth: 1
    }
  ]
};
export const deafultPatientDetails = {
  "patientId": "",
  "patientLastName": "",
  "patientFirstName": "",
  "patientDOB": null,
  "phoneNumber": "",
  "email": "",
  "appointmentDate": null,
  "insurancename": "",
  "memberId": 0,
  "groupNumber": "",
  "policyHolderRelationship": "",
  "requestNpi": ""
}

export const dummyPatients = [
  { id: 1, name: 'John Doe' },
  { id: 2, name: 'Jane Smith' },
  { id: 3, name: 'Alice Johnson' },
];

export const appointmentConstants = {
  defaultTitle: '',
  defaultNotes: '',
  modalWidth: 400,
  alertDuration: 6000,
  successMessage: 'Appointment created successfully',
  updateMessage: 'Appointment updated successfully',
  deleteMessage: 'Appointment deleted successfully',
  pastAppointmentMessage: 'Cannot create an appointment for past dates.',
  pastEditMessage: "You cannot edit appointments in the past.",
  patientId: "60d5f9f8f8f8f8f8f8f8f8f8",
  providerId: "60d5f9f8f8f8f8f8f8f8f8f9"
};

export const patientsOverviewGraphEmptyData = {
  labels: [trimmedStr(getCurrentMonthStr(getCurrMonth()), 3)],
  datasets: [
    {
      label: 'New Patients Added',
      data: [],
      backgroundColor: colors?.lightGreen,
      borderColor: colors?.themeGreen,
      borderWidth: 1
    },
    {
      label: 'Patients having insurance',
      data: [],
      backgroundColor: colors?.lightYellow,
      borderColor: colors?.yellow,
      borderWidth: 1
    },
    {
      label: 'Patients missing insurance',
      data: [],
      backgroundColor: colors?.lightRed,
      borderColor: colors?.danger,
      borderWidth: 1
    }
  ]
};

export const noDataInGraphStr = 'No data in graph to show'

export const invalidDateStr = 'Invalid Date Interval'

export const dateFilterIntervals = [
  { label: 'Today', value: 'today', selected: true },
  { label: 'Week', value: 'week', selected: false },
  { label: 'One Month', value: 'month', selected: false },
  { label: '6 months', value: "6months", selected: false },
  { label: 'Year', value: 'year', selected: false }
]

export const succesfulPatientUpdationStr = 'Patient Updated Successfully'
export const failedPatientUpdationStr = 'Failed to update patient'

export const PRESET_DURATIONS = [
  { label: '15 min', value: 15 },
  { label: '30 min', value: 30 },
  { label: '45 min', value: 45 },
  { label: '1 hr', value: 60 },
];

export const specialityTabs = ['In Plan', 'Out of Plan', "Both"]

export const emptyClaimsTable = [{
  firstName: '',
  lastName: '',
  dob: ''
}]

export const errorFetchingFieldsStr = 'Error fetching data to update the fields'
export const taskScheduledSuccessfullyStr = 'Task scheduled successfully'
export const taskScheduledFailedStr = 'Failed to schedule task'
export const enterAllReqFields = "Enter all the required fields"
export const errFetchingDataStr = 'Error fetching data'
export const failedToSearchDataStr = 'Failed to search this user'
export const succesfulScheduleDateUpdationStr = 'Schedule Date Updated Successfully'
export const successfullyDeletedTaskStr = 'Task Deleted Successfully'
export const failedRequestStr = 'Request Failed'
export const succesfullReqStr = 'Request Successful'
export const taskAlreadyCancelledStr = 'Task Already Cancelled'
export const failedNetworkReqStr = 'Network Request Failed'

export const defaultPatientData = {
  patientLastName: null,
  patientFirstName: null,
  patientDOB: null,
  phoneNumber: null,
  email: null,
  appointmentDate: null,
  insurancename: [],
  memberId: null,
  groupNumber: null,
  policyHolderRelationship: null,
  requestNpi: null
}


export const alphaNumericValidationStr = 'This field only accepts Alphanumeric Characters'
export const onlyAlphabetsValidationStr = 'This field only accepts Alphabets.'

export const defaultELigibilitySearchFormData = {
  patientLastName: "",
  patientFirstName: "",
  patientDOB: "",
  dateOfService: dayjs().toISOString(),
  phoneNumber: "",
  socialSecurityNumber: "",
  email: "",
  insurancename: "",
  patientMemberId: "",
  isSubscriberPatient: "true",
  transactionDate: dayjs().toISOString(),
}
export const validationErrorStr = "Validation error. Please correct the email."
export const invalidPatientStr = "Invalid patient state."
export const subscriberNotFoundApiMsgStr =   "Subscriber/Insured Not Found \r\n"
export const invalidPatientEligibilityStr = "Invalid patient Eligibility."

export const initialSchedule = {
  monday: [{ startTime: '09:00', endTime: '17:00' }],
  tuesday: [{ startTime: '09:00', endTime: '17:00' }],
  wednesday: [{ startTime: '09:00', endTime: '17:00' }],
  thursday: [{ startTime: '09:00', endTime: '17:00' }],
  friday: [{ startTime: '09:00', endTime: '17:00' }],
  saturday: [],
  sunday: [],
};

export const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const successMessages = {
  saveScheduleSuccess: 'Doctor information added',
};

export const errorMessages = {
  saveScheduleError: 'Error saving doctor information',
};

export const errorMessage= {
  fetchScheduleError: 'There was an error fetching the schedule!',
  fetchDoctorInfoError: 'There was an error fetching the doctor information!',
};

export const failedGetApi = (message) => {
  toast.error(message); 
};

export const noDataInTableStr = 'No data to show in table'
export const noDataForPayerDetailsStr = 'No data to show for payer details'
export const errorInGettingPayerDetailsStr = 'Error in getting payer details'

export  const timeAdjustments = {
  'Today': { unit: 'day', value: 1 },
  'Week': { unit: 'week', value: 1 },
  'One Month': { unit: 'month', value: 1 },
  '6 months': { unit: 'month', value: 6 },
  'Year': { unit: 'year', value: 1 },
};

export const noPendingClaimsDataFoundStr = 'No data for pending claims found'
export const noApprovedClaimsDataFoundStr = 'No data for approved claims found'
export const noReasonFoundForClaimsStr = "Couldn't find any reason for claims denial" 

export const claimNumbersDashboardStaticData = [
  { heading: 'Submitted', text: '--'},
  { heading: 'Approved', text:  '--' ,loader:false},
  { heading: 'Pending', text:  '--' ,loader:false},
  { heading: 'Denied', text: '--' }
]

export const noValidFilterSelectedStr = 'No valid filter selected for date adjustment'
export const failedToGetProviderOrganizationStr = 'Failed to get provider organization name'

export const API_BASE_URL_NEW = process.env.REACT_APP_API_URL_NEW;
