import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { PatientActionBtn } from './configuration/PatientTableConfiguration';
import { formatGridArray } from '../../Pages/Patients/PatientFunctions';
import { sharedStyles } from '../../sharedStyles';
import { failedApiReq } from '../../utils/reusableFunctions';
import { get_GetAllPatientsDataAsync } from '../../services/addPatient';


const GridComponentPatients = () => {
  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: async (params) => {
          const page = params.endRow / perPage;
          try {
            gridApi.showLoadingOverlay();
            const data = await get_GetAllPatientsDataAsync(null, page, perPage);
            const dataArray = formatGridArray(data?.data)
            params.successCallback(dataArray, data.count);
          }
          catch (e) {
            params.successCallback([], 0);
            failedApiReq(e)
          }
          finally {
            gridApi.hideOverlay();
          }
        }
      }

      gridApi.setDatasource(dataSource);
    }
  }, [gridApi]);

  const columnDefs = () => {
    return [
      { headerName: 'Date Added', field: 'Date_Added', width: 170, filter: true },
      { headerName: 'Appt', field: 'Appt', width: 117, filter: true },
      { headerName: 'Patient', field: 'Patient', filter: true, width: 232 },
      { headerName: 'Phone', field: 'Phone', filter: true, width: 123 },
      { headerName: 'Insurance', field: 'Insurance', width: 227, filter: true },
      { headerName: 'Deductible', field: 'Deductible', width: 122, cellRenderer: '', filter: true },
      { headerName: 'Copay', field: 'Copay', filter: true, cellRenderer: '', width: 91 },
      { headerName: 'Date Updated', field: 'Date_Updated', width: 126 },
      { headerName: '', field: '', cellRenderer: PatientActionBtn, flex: 1, minWidth: 140 },
    ];
  };



  return (
    <>
      <div className="ag-theme-quartz"
        style={sharedStyles?.h500}  >
        <AgGridReact
          pagination={true}
          rowModelType={'infinite'}
          paginationPageSize={perPage}
          cacheBlockSize={perPage}
          onGridReady={onGridReady}
          rowHeight={60}
          columnDefs={columnDefs()}
          overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
        />
      </div>
    </>
  )
};

export default GridComponentPatients;