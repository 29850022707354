import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Button,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useNavigate } from "react-router-dom";
import { post_eligibilitysearchdata } from "../../services/registration";
import styles from "../../css/addpatient.module.css";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import TableHeader from "../../components/TableHeader";
import {  fetchPatientFormDetails, handleEmailError } from "./EligibilityFunctions";
import { defaultELigibilitySearchFormData, invalidPatientEligibilityStr, invalidPatientStr, subscriberNotFoundApiMsgStr } from "../../utils/staticData";
import { ymdDateFormat } from "../../utils/DateFunctions";
import { failedApiReq, getLocalRequestNpi } from "../../utils/reusableFunctions";
import { sharedStyles } from "../../sharedStyles";

const Search = () => {
  const navigate = useNavigate();
  const { formWrapper } = styles;
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [relationship, setRelationship] = useState("Self");
  const [dependentFieldsVisible, setDependentFieldsVisible] = useState(false);
  const [patientSelected, setPatientSelected] = useState('')
  const [loading, setLoading] = useState(false)


  const [userDetails, setUserDetails] = useState(defaultELigibilitySearchFormData);

  const handleRelationshipChange = (event, value) => {
    setRelationship(value);
    setDependentFieldsVisible(value === "Spouse" || value === "Child");
  };

  const handleChange = (event, value) => {
    if (event && event.target) {
      const { name, value: targetValue } = event.target;

      setUserDetails((prevState) => ({
        ...prevState,
        [name]: targetValue,
      }));
      if (name === "FirstName") {

        if (selectedPatient) {
          setSelectedPatient((prevPatient) => ({
            ...prevPatient,
            patientFirstName: targetValue,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            patientFirstName: targetValue,
          }));
        }
        userDetails.patientFirstName = targetValue

      }
      if (name === "LastName") {

        if (selectedPatient) {
          setSelectedPatient((prevPatient) => ({
            ...prevPatient,
            patientLastName: targetValue,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            patientLastName: targetValue,
          }));
        }
        userDetails.patientLastName = targetValue;
      }
      if (name === "insurance") {

        if (selectedPatient) {
          setSelectedPatient((prevPatient) => ({
            ...prevPatient,
            insurancename: targetValue,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            insurancename: targetValue,
          }));
        }
        userDetails.insurancename = targetValue;

      }
      if (name === "email") {
        if (selectedPatient) {
          setSelectedPatient((prevPatient) => ({
            ...prevPatient,
            email: targetValue,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            email: targetValue,
          }));
        }

      }
      if (name === "phone") {
        if (selectedPatient) {
          setSelectedPatient((prevPatient) => ({
            ...prevPatient,
            phoneNumber: targetValue,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            phoneNumber: targetValue,
          }));
        }
      }

      if (name == "memberid") {
        {
          if (selectedPatient) {
            setSelectedPatient((prevPatient) => ({
              ...prevPatient,
              memberId: targetValue,
            }));
          } else {
            setUserDetails((prevState) => ({
              ...prevState,
              memberId: targetValue,
            }));
          }

        }

      }
    }
  };

  const searchbtn = async (e) => {
    e.preventDefault();

    if (emailError) {
      handleEmailError()
      return;
    }
  

    try {
      setLoading(true)
      const today = dayjs().toISOString();
        const formattedDOB = ymdDateFormat(new Date(userDetails?.patientDOB));
      const postsearchdata = await post_eligibilitysearchdata({...userDetails,dateOfService: today,
        patientDOB: formattedDOB });
 if (
          postsearchdata?.insuranceDiscoveryDto?.apiResponseMessage ===
          invalidPatientStr ||
          postsearchdata?.insuranceDiscoveryDto?.apiResponseMessage ===
          subscriberNotFoundApiMsgStr
        ) {
          toast.error(invalidPatientEligibilityStr);
        } else {
          postsearchdata &&
            navigate("/EligibilitySearchResults?through=Eligibility", {
              state: { searchData: {...postsearchdata,selectedPatientId:patientSelected?.id }},
            });
        }
    } catch (error) {
      failedApiReq()
    }
    finally{
      setLoading(false)
    }
  };
  useEffect(()=>{
    if (patientSelected?.id) {
      fetchPatientFormDetails(patientSelected,setSelectedPatient,setUserDetails,userDetails)
        
      }
    else {
      setSelectedPatient(null)
      setUserDetails(defaultELigibilitySearchFormData);
    }
},[patientSelected])

return (
    <Box>
      <TableHeader setFilterPatient={setPatientSelected} idNeeded/>
      <Box>
        <Box className={formWrapper}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em", color: "purple" }} fullWidth>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    name="FirstName"
                    onChange={handleChange}
                    color="error"
                    required
                    inputProps={{ maxLength: 20 }}
                    value={selectedPatient ? selectedPatient.patientFirstName : userDetails.patientFirstName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    name="LastName"
                    onChange={handleChange}
                    color="error"
                    required
                    inputProps={{ maxLength: 20 }}
                    value={selectedPatient ? selectedPatient.patientLastName : userDetails.patientLastName}
                  />
                </FormControl>
              </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    variant="outlined"
                    name="PatientDOB"
                    required
                    value={selectedPatient && dayjs(selectedPatient.patientDOB)}
                    onChange={handleChange}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of service"
                    variant="outlined"
                    name="dateofservice"
                    onChange={handleChange}
                    value={selectedPatient ? dayjs(selectedPatient.dateOfService) : dayjs(userDetails.dateOfService)}
                    color="error"
                    required
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <TextField
                    label="Patient Phone"
                    variant="outlined"
                    name="phone"
                    color="primary"
                    onChange={handleChange}
                    value={selectedPatient ? selectedPatient.phoneNumber : userDetails.phoneNumber}
                  />
                </FormControl>
              </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <TextField
                  label="Email Address"
                  variant="outlined"
                  name="email"
                  onChange={handleChange}
                  color="info"
                  error={Boolean(emailError)}
                  helperText={emailError}
                  value={selectedPatient ? selectedPatient.email : userDetails.email}

                />
              </FormControl>
            </Grid>



              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <TextField
                    label="Insurance Name"
                    variant="outlined"
                    color="warning"
                    name="insurance"
                    onChange={handleChange}
                    value={selectedPatient ? selectedPatient.insurancename : userDetails.insurancename}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <TextField
                    label="Member ID"
                    variant="outlined"
                    color="warning"
                    name="memberid"

                    onChange={handleChange}
                    value={selectedPatient ? selectedPatient.patientMemberId : userDetails.patientMemberId}

                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: '2em' }} fullWidth>
                  <Autocomplete
                    options={['Self', 'Spouse', 'Child']}
                    value={relationship}
                    onChange={handleRelationshipChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Relationship"
                        variant="outlined"
                        color="warning"
                        required
                      />
                    )}
                  />
                </FormControl>
            </Grid>
              {/* Conditionally render dependent fields */}
              {dependentFieldsVisible && (
                <>
                  <Grid item xs={12} sm={6} lg={6}>
                    <FormControl sx={{ marginBottom: '2em' }} fullWidth>
                      <TextField
                        label="Dependent First Name"
                        variant="outlined"
                        color="warning"
                        name="dependentFirstName"
                        onChange={handleChange}
                        value={userDetails.dependentFirstName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <FormControl sx={{ marginBottom: '2em' }} fullWidth>
                      <TextField
                        label="Dependent Last Name"
                        variant="outlined"
                        color="warning"
                        name="dependentLastName"
                        onChange={handleChange}
                        value={userDetails.dependentLastName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <FormControl sx={{ marginBottom: '2em' }} fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Dependent Date of Birth"
                          variant="outlined"
                          name="dependentDOB"
                          value={dayjs(userDetails.dependentDOB)}
                          onChange={(date) => handleChange({ target: { name: 'dependentDOB', value: date } })}
                          color="error"
                          required
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <FormControl sx={{ marginBottom: '2em' }} fullWidth>
                      <TextField
                        label="Dependent Member ID"
                        variant="outlined"
                        color="warning"
                        name="dependentMemberId"
                        onChange={handleChange}
                        value={userDetails.dependentMemberId}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
             <Grid item xs={12}>
    <Box sx={{ display: 'flex', marginTop: '2em' }}>
      <Button variant="outlined" color="inherit">
        Cancel
      </Button>
      <Button variant="contained" color="success" onClick={searchbtn} sx={sharedStyles?.ml5}>
        {loading ? <CircularProgress color="inherit" size={20}/> : 'Search'}
      </Button>
    </Box>
  </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Search;