import React, { useEffect, useState } from 'react';
import HorizontalHeadingPlusText from '../shared/HorizontalHeadingPlusText';
import { Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { update_UpdatePatientInsuranceDetails } from '../../services/registration';
import toast from 'react-hot-toast';
import styles from "../../css/discoveryDetails.module.css";
import { formatPatientInsuranceData, getTabNameLabel } from '../../Pages/Discovery/DiscoveryFunctions';
import SyncButton from './SyncButton';

const DetailTab = ({ data, patientInsuranceTabData, tabname, patientId }) => {
  const { leftBox, header, rightBox, button, buttonGrid } = styles; 
  const detailData = [
    { name: 'Status', value: data?.status },
    { name: 'Effective Date', value: data?.effectiveDate || 'N/A' },
    { name: 'Expiry Date', value: data?.expiryDate || 'N/A' },
    { name: 'Plan Name', value: data?.planName || 'N/A' },
    { name: 'Policy Type', value: data?.policyType || 'N/A' },
    { name: 'Group Number', value: data?.groupNumber || 'N/A' },
    { name: 'Plan Network ID', value: data?.planNetworkID || 'N/A' },
  ];

  const [patientInsuranceDetailTabData, setPatientInsuranceDetailTabData] = useState([]); 
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setPatientInsuranceDetailTabData(formatPatientInsuranceData(patientInsuranceTabData))
  }, [patientInsuranceTabData])

  const handleSavePatientsDetails = async (tabName, patientId) => {
    try {
      setIsLoading(true)
      const patientUpdatedData = await update_UpdatePatientInsuranceDetails(tabName, patientId);
      if (patientUpdatedData?.data?.insuranceDiscoveryEntities?.[0]?.planCoverageSummary) {
        setPatientInsuranceDetailTabData(formatPatientInsuranceData(patientUpdatedData?.data?.insuranceDiscoveryEntities?.[0]?.planCoverageSummary));
      } else {
        toast('Plan detail is empty');
      }      
    } catch (error) {
      console.error(error);
    }
    finally{
      setIsLoading(false)
    }
  }
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={leftBox}>
            <Typography variant="h5" align="center" className={header}>
              Payer
            </Typography>
            <Typography align="center" variant="h5" className={header}>
              {getTabNameLabel(tabname)}
            </Typography>
            <hr/>
            {detailData?.length && detailData?.map((item, index) => (
              <HorizontalHeadingPlusText heading={item?.name} text={item?.value} key={index} />
            ))}
          </Paper>
        </Grid>

        <Grid item xs={12} md={2} className={buttonGrid}>
            <SyncButton onClick={()=>handleSavePatientsDetails(tabname, patientId)} isLoading={isLoading}/> 
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={rightBox}>
            <Typography variant="h5" align="center" className={header}>
              Practice
            </Typography>
            <Typography align="center" variant="h5" className={header}>
            {getTabNameLabel(tabname)}
            </Typography>
            <hr />
            {
             patientInsuranceDetailTabData?.length && patientInsuranceDetailTabData?.map(({heading,text},index)=>{
                return(
                  <HorizontalHeadingPlusText heading={heading} text={text|| 'N/A'} key={index} /> 
                )
              })
            }
          </Paper>
        </Grid>
      </Grid>
  </div> 
  );
};

export default DetailTab;
