import React, { useEffect, useState } from 'react'
import { get_GetPatientDataById, getInsurance_Details } from '../../services/addPatient';
import { useParams } from 'react-router-dom';
import { Autocomplete, Box, Grid, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { sharedStyles } from '../../sharedStyles';
import BoxComponent from '../../components/BoxComponent';
import { ContactMailSharp } from '@mui/icons-material';
import { colors } from '../../utils/colors';
import { IoIosJournal } from 'react-icons/io';
import { CgCalendarDates } from 'react-icons/cg';
import { FaCcAmazonPay, FaHandsHelping, FaIdCard, FaMinusCircle } from 'react-icons/fa';
import { PiShareNetworkDuotone } from 'react-icons/pi';
import { SiInstatus } from 'react-icons/si';


const InsuranceDetails = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsloading] = useState(false)
    const [patient, setPatient] = useState({
        patientFirstName: '',
        patientLastName: '',
        patientDOB: dayjs(),
        transactionDate: dayjs(),
        dateOfService: dayjs(),
        patientState: 'VA',
    });
    const { id } = useParams();
    const [insurance, setInsurance] = useState({
        firstName: '',
        lastName: '',
        phoneNum: '',
        transactionDate: '',
        insuranceDate: ''
    });

    const topCardsItems = [
        { name: 'Status', value:  'N/A', icon: <SiInstatus fill={colors?.themeGreen} /> },
        { name: 'Network', value: 'N/A', icon: <PiShareNetworkDuotone fill={colors?.themeGreen} /> },
        { name: 'Payer', value: 'N/A', icon: <FaCcAmazonPay fill={colors?.themeGreen} /> },
        { name: 'Member Id', value: 'N/A', icon: <FaIdCard fill={colors?.themeGreen} /> },
        { name: 'Copay', value:  'N/A', icon: <FaHandsHelping fill={colors?.themeGreen} /> },
        { name: 'Deductible', value:  'N/A', icon: <FaMinusCircle fill={colors?.themeGreen} /> },
        { name: 'Plan Begin Date', value:  'N/A', icon: <CgCalendarDates fill={colors?.themeGreen} /> },
        { name: 'Plan Type', value: 'N/A', icon: <IoIosJournal fill={colors?.themeGreen} /> },
    ]

    const getUser = async () => {
        try {
            const response = await get_GetPatientDataById(id);
            const { firstName, lastName, dob } = response?.data;
            const { address } = response?.data?.address
            setPatient({
                ...patient,
                patientFirstName: firstName,
                patientLastName: lastName,
                patientDOB: dayjs(dob),
                patientState: 'VA'
            });
        } catch (error) {
            console.log(error)
        }
    };


    const handleSubmit = async () => {
        setIsloading(true)
        const { transactionDate, patientDOB } = patient;
        const x = dayjs(transactionDate).format('YYYY-MM-DD');
        const y = dayjs(patientDOB).format('YYYY-MM-DD');

        const obj = {
            ...patient,
            transactionDate: x,
            patientDOB: y,
        }
        try {
            const response = await getInsurance_Details(obj);
            response?.insuranceDiscoveryDtosList?.map(({ patientData, transactionDate, payerName }) => {
                setInsurance({
                    firstName: patientData?.firstName,
                    lastName: patientData?.lastName,
                    insuranceDate: payerName,
                    transactionDate: transactionDate,
                    phoneNum: patientData?.phone
                })
            })
            setData(response);
            console.log(response)
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error)
        } finally {
            setIsloading(false)
        }
    }


    useEffect(() => {
        getUser();
    }, [id]);

    useEffect(() => {
        if (patient.patientFirstName) {
            handleSubmit();
        }
    }, [patient.patientFirstName]);


    return (
        <Box>
            <Grid container rowSpacing={3} columnSpacing={8}>
                {topCardsItems?.map((elem, index) => {
                    return (
                        <Grid item xs={12} sm={6} lg={3} key={index} >
                            <Box sx={{ boxShadow: '0px 4px 8px #5E8A75', borderRadius: 2, bgcolor: 'white', height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, cursor: 'pointer', }}>
                                {elem?.icon}
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ fontWeight: 'bold', color: colors?.themeGreen }}>{elem?.name}</Typography>
                                    <Typography>{elem?.value}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default InsuranceDetails
