import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Typography, IconButton } from '@mui/material';
import { AddCircle, RemoveCircle, ContentCopy } from '@mui/icons-material';
import { fetchSchedule, saveSchedule, fetchDoctorInfo } from '../../services/DoctorAvailabilityService';
import { toast } from 'react-hot-toast'; 
import "../../css/DoctorAvailability.css";
import { colors } from "../../utils/colors";
import { getLocalData } from "../../utils/localStorageFunctions";
import { daysOfWeek, initialSchedule,successMessages, errorMessages,errorMessage,failedGetApi } from '../../utils/staticData';
import { isValidEmail, isValidPhoneNumber } from '../../utils/regexTestFunctions';

let npiInfo = getLocalData('selectedNpi');

const Schedule = () => {
    const [schedule, setSchedule] = useState(initialSchedule);
    const [doctorInfo, setDoctorInfo] = useState({ name: '', specialty: '', contactInfo: { email: '', phone: '' } });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const scheduleResponse = await fetchSchedule();
                const fetchedSchedule = scheduleResponse?.data?.schedule;
                if (fetchedSchedule) {
                    setSchedule(fetchedSchedule);
                }
            } catch (error) {
                failedGetApi(errorMessage.fetchScheduleError);
            }

            try {
                const doctorInfoResponse = await fetchDoctorInfo();
                const fetchedDoctorInfo = doctorInfoResponse?.data;
                if (fetchedDoctorInfo) {
                    setDoctorInfo(fetchedDoctorInfo);
                }
            } catch (error) {
                failedGetApi(errorMessage.fetchDoctorInfoError);
            }
        };

        fetchData();
    }, []);

    const validateFields = () => {
        let validationErrors = {};

        if (!doctorInfo?.specialty) {
            validationErrors.specialty = 'Specialty is required';
        }

        if (!doctorInfo?.contactInfo?.email) {
            validationErrors.email = 'Email is required';
        } else if (!isValidEmail(doctorInfo?.contactInfo?.email)) {
            validationErrors.email = 'Email is invalid';
        }

        if (!doctorInfo?.contactInfo?.phone) {
            validationErrors.phone = 'Phone number is required';
        } else if (!isValidPhoneNumber(doctorInfo?.contactInfo?.phone)) {
            validationErrors.phone = 'Phone number is invalid';
        }
        setErrors(validationErrors);

        return Object.keys(validationErrors).length === 0;
    };

    const handleDoctorInfoChange = (field, value) => {
        setDoctorInfo({
            ...doctorInfo,
            [field]: value,
        });
        setErrors({ ...errors, [field]: '' });
    };

    const handleContactInfoChange = (field, value) => {
        setDoctorInfo({
            ...doctorInfo,
            contactInfo: {
                ...doctorInfo?.contactInfo,
                [field]: value,
            },
        });
        setErrors({ ...errors, [field]: '' });
    };

    const handleTimeChange = (day, index, timeType, value) => {
        const updatedDaySchedule = [...schedule[day]];
        updatedDaySchedule[index][timeType] = value;
        setSchedule({ ...schedule, [day]: updatedDaySchedule });
    };

    const handleAddSlot = (day) => {
        setSchedule((prevSchedule) => ({
            ...prevSchedule,
            [day]: [...prevSchedule[day], { startTime: '', endTime: '' }],
        }));
    };

    const handleRemoveSlot = (day, index) => {
        const updatedDaySchedule = [...schedule[day]];
        updatedDaySchedule.splice(index, 1);
        setSchedule({ ...schedule, [day]: updatedDaySchedule });
    };

    const handleCopyPreviousDaySlot = (day) => {
        const currentDayIndex = daysOfWeek.indexOf(day);
        const previousDay = daysOfWeek[currentDayIndex - 1];
        if (previousDay && schedule[previousDay]?.length > 0) {
            const lastSlot = schedule[previousDay][schedule[previousDay].length - 1];
            setSchedule((prevSchedule) => ({
                ...prevSchedule,
                [day]: [...prevSchedule[day], { ...lastSlot }],
            }));
        }
    };

    const handleSave = () => {
        if (!validateFields()) return;

        const payload = {
            doctorId: npiInfo?.npiNumber?.toString() || null,
            name: npiInfo?.name,
            specialty: doctorInfo?.specialty,
            contactInfo: {
                email: doctorInfo?.contactInfo?.email,
                phone: doctorInfo?.contactInfo?.phone,
            },
            schedule:Object.entries(schedule)?.map((elem)=>{
                if(elem?.[1]?.[0]?.startTime && elem?.[1]?.[0]?.endTime)  return(
                      {
                          startTime: elem?.[1]?.[0]?.startTime,
                          endTime: elem?.[1]?.[0]?.endTime,
                          day: elem?.[0]
                    
                      }
                  )
                  else return false
              })?.filter((elem)=> elem),
        };

        const handleSaveSchedule = async (payload) => {
            try {
                const response = await saveSchedule(payload);
                toast.success(successMessages.saveScheduleSuccess);
            } catch (error) {
                toast.error(errorMessages.saveScheduleError);
            }
        };
        handleSaveSchedule(payload);
    };

    return (
        <Grid container spacing={3} justifyContent="center" className="schedule-container">
            <Grid item xs={12} sm={8}>
                <Paper elevation={3} className="doctor-info-container">
                    <Typography variant="h6" gutterBottom>
                        Doctor Information
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Name"
                                variant="outlined"
                                value={npiInfo?.name}
                                onChange={(e) => handleDoctorInfoChange('name', e.target.value)}
                                error={!!errors?.name}
                                helperText={errors?.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Specialty"
                                variant="outlined"
                                value={doctorInfo?.specialty}
                                onChange={(e) => handleDoctorInfoChange('specialty', e.target.value)}
                                error={!!errors?.specialty}
                                helperText={errors?.specialty}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                type="email"
                                value={doctorInfo?.contactInfo?.email}
                                onChange={(e) => handleContactInfoChange('email', e.target.value)}
                                error={!!errors?.email}
                                helperText={errors?.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Phone"
                                variant="outlined"
                                type="tel"
                                value={doctorInfo?.contactInfo?.phone}
                                onChange={(e) => handleContactInfoChange('phone', e.target.value)}
                                error={!!errors?.phone}
                                helperText={errors?.phone}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={8}>
                <Paper elevation={3} className="day-schedule">
                    <Typography variant="h6" gutterBottom>
                        Weekly Hours
                    </Typography>
                    {Object.keys(schedule)?.map((day) => (
                        <div key={day} className="day-schedule">
                            <Typography variant="subtitle1">
                                {day.charAt(0).toUpperCase() + day.slice(1)}
                            </Typography>
                            {schedule[day]?.map((slot, index) => (
                                <Grid container spacing={2} key={index} alignItems="center">
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            label="Start Time"
                                            type="time"
                                            variant="outlined"
                                            value={slot?.startTime}
                                            onChange={(e) => handleTimeChange(day, index, 'startTime', e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            label="End Time"
                                            type="time"
                                            variant="outlined"
                                            value={slot?.endTime}
                                            onChange={(e) => handleTimeChange(day, index, 'endTime', e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton onClick={() => handleRemoveSlot(day, index)}>
                                            <RemoveCircle color="error" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid container spacing={2} justifyContent="flex-start">
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        startIcon={<AddCircle />}
                                        onClick={() => handleAddSlot(day)}
                                        className="add-slot-button"
                                    >
                                        Add Slot
                                    </Button>
                                </Grid>
                                {daysOfWeek.indexOf(day) > 0 && (
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            startIcon={<ContentCopy />}
                                            onClick={() => handleCopyPreviousDaySlot(day)}
                                            className="copy-previous-slot-button"
                                        >
                                            Copy Previous
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    ))}
                </Paper>
            </Grid>

            <Grid item xs={12} sm={8}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    className="save-button"
                >
                    Save Schedule
                </Button>
            </Grid>
        </Grid>
    );
};
export default Schedule;