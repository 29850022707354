import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid, Tabs } from '@mui/material';
import styles from '../../css/addpatient.module.css'
import { useState, useEffect } from 'react';
import MyTabs from '../../components/tabs/MyTabs'
import { useLocation, useNavigate, } from 'react-router-dom';
import Plandetail from './Plandetail';
import { get_GetInusuranceDiscoveryByPatientId } from '../../services/registration';
import { CgCalendarDates } from "react-icons/cg";
import { IoIosJournal } from "react-icons/io";
import { PiShareNetworkDuotone } from "react-icons/pi";
import { FaCcAmazonPay } from "react-icons/fa";
import { FaHandsHelping } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { colors } from '../../utils/colors';
import { FaIdCard } from "react-icons/fa";
import DetailsHeader from '../../components/shared/DetailsHeader';
import { SiInstatus } from 'react-icons/si';
import {fetchDiscoveryDetails, fetchPatientData} from './DiscoveryFunctions'
import { sharedStyles } from '../../sharedStyles';

function Discoverydetails1() {
  const { formWrapper } = styles;
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const [searchdata, setsearchdata] = useState([]);
  const [tabData, setTabData] = useState()
  const [detailData,setDetailData] = useState()
  const [detailsLoading, setDetailsLoading] = useState(false)

  // route handlers
  const rowDataString = new URLSearchParams(location.search).get('rowData');
  const through = new URLSearchParams(location.search).get('through');
  const rowData = rowDataString ? JSON.parse(decodeURIComponent(rowDataString)) : null;
  const navigate = useNavigate();

  useEffect(() => {
    fetchDiscoveryDetails(rowData,setsearchdata,setTabData)
    fetchPatientData(rowData,setDetailData,setDetailsLoading)
  }, []);


  // states using route handlers
  const [topRightButtons] = useState([
    {
      name: 'Check Eligibility',
      action: () => navigate('/EligibilitySearchResults?', { state: { preSelectedTab: 2, rowData } })
    },
    { name: 'New Search', action: () => navigate('/discoveryTab?preSelectedTab=2') },
    { name: 'Back to Results', action: () => navigate(through === 'Discovery' ? (-1) : '/discoveryTab?preSelectedTab=1') }
  ])


  const handleTabChange = (newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const tabs =
    [
      { label: "Plan Detail", value: <Plandetail tabname="detail" rowData={rowData} data={tabData} detailData={detailData?.data?.planDetails} detailsLoading={detailsLoading}/> },
      { label: "Coverage Details", value: <Plandetail tabname="coverage" rowData={rowData} data={tabData} detailData={detailData?.data?.coverageDetails}/> },
      { label: "DemographicInfo", value: <Plandetail tabname="DemographicInfo" rowData={rowData} data={tabData} detailData={detailData?.data?.demographicInfo} /> },
      { label: "In-Network", value: <Plandetail tabname="Innetwork" rowData={rowData} data={tabData} detailData={detailData?.data?.inNetworkDetails}/>, changeTab: 3 },
      {
        label: "Out-of-Network", value: <Plandetail tabname="Outofnetwork" rowData={rowData} data={tabData} detailData={detailData?.data?.outOfNetworkDetails}/>, changeTab: 4,
      },
      {
        label: "Speciality", value: <Plandetail tabname="Speciality" rowData={rowData} data={tabData} detailData={detailData?.data?.planDetails}/>, changeTab: 5,
      },
    ]

  const topCardsItems = [
    { name: 'Status', value: rowData.Status || 'N/A', icon: <SiInstatus fill={colors?.themeGreen} /> },
    { name: 'Network', value: searchdata.isProviderInNetwork || 'N/A', icon: <PiShareNetworkDuotone fill={colors?.themeGreen} /> },
    { name: 'Payer', value: rowData.Insurance || 'N/A', icon: <FaCcAmazonPay fill={colors?.themeGreen} /> },
    { name: 'Member Id', value: searchdata.memberId || 'N/A', icon: <FaIdCard fill={colors?.themeGreen} /> },
    { name: 'Copay', value: rowData.Copay || 'N/A', icon: <FaHandsHelping fill={colors?.themeGreen} /> },
    { name: 'Deductible', value: searchdata.deductibleData || 'N/A', icon: <FaMinusCircle fill={colors?.themeGreen} /> },
    { name: 'Plan Begin Date', value: searchdata.effectiveDate || 'N/A', icon: <CgCalendarDates fill={colors?.themeGreen} /> },
    { name: 'Plan Type', value: searchdata.policyType || 'N/A', icon: <IoIosJournal fill={colors?.themeGreen} /> },
  ]

  return (
    <Box sx={{ width: { xs: '100%' } }}>
      <DetailsHeader 
      topRightButtons={topRightButtons} 
      name={rowData?.Patient} 
      dob={rowData?.DOB} 
      pageName={through !== 'Discovery' && 'Discoverydetails1'}
      id={through !== 'Discovery' && rowData?.ID}
      />
      <Box >
        <Box>

          <Box className={formWrapper} sx={sharedStyles?.mt2em}>
            <Grid container rowSpacing={3} columnSpacing={8}>

              {topCardsItems?.map((elem, index) => {
                return (
                  <Grid item xs={12} sm={6} lg={3} key={index} >
                    <Box sx={{ boxShadow: '0px 4px 8px #5E8A75', borderRadius: 2, bgcolor: 'white', height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, cursor: 'pointer', }}>
                      {elem?.icon}
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', color: colors?.themeGreen }}>{elem?.name}</Typography>
                        <Typography>{elem?.value}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
            <Box sx={{ width: '100%', mt: 5 }}>
              <Tabs>
                <MyTabs tabs={tabs} onChange={handleTabChange} activeTab={tabIndex}
                />
              </Tabs>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default Discoverydetails1;
