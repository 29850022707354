import React, { useState } from 'react';
import HorizontalHeadingPlusText from '../shared/HorizontalHeadingPlusText';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { update_UpdatePatientInsuranceDetails } from '../../services/registration';
import toast from 'react-hot-toast';
import styles from "../../css/discoveryDetails.module.css";

const EligibilityDetailTab = ({ data, patientInsuranceTabData, tabname, patientId }) => {
  const { leftBox, header, rightBox, button, buttonGrid } = styles; 
  const detailData = [
    { name: 'Status', value: data?.status },
    { name: 'Effective Date', value: data?.effectiveDate || 'N/A' },
    { name: 'Expiry Date', value: data?.expiryDate || 'N/A' },
    { name: 'Plan Name', value: data?.planName || 'N/A' },
    { name: 'Policy Type', value: data?.policyType || 'N/A' },
    { name: 'Group Number', value: data?.groupNumber || 'N/A' },
    { name: 'Plan Network ID', value: data?.planNetworkID || 'N/A' },
  ];

  const [patientInsuranceDetailTabData, setPatientInsuranceDetailTabData] = useState(patientInsuranceTabData); 

  const handleSavePatientsDetails = async (tabName, patientId) => {
    try {
      const patientUpdatedData = await update_UpdatePatientInsuranceDetails(tabName, patientId);
      if (patientUpdatedData?.data?.insuranceDiscoveryEntities?.[0]?.planCoverageSummary) {
        setPatientInsuranceDetailTabData(patientUpdatedData?.data?.insuranceDiscoveryEntities?.[0]?.planCoverageSummary);
      } else {
        toast('Plan detail is empty');
      }      
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={leftBox}>
            <Typography variant="h5" align="center" className={header}>
              Payer
            </Typography>
            <Typography align="center" variant="h5" className={header}>
              {tabname === "detail"
                ? "Plan Details"
                : tabname === "coverage"
                ? "Coverage Details"
                : tabname === "DemographicInfo"
                ? "DemographicInfo Details"
                : tabname === "Innetwork"
                ? "In Network"
                : tabname === "Outofnetwork"
                ? "Out of Network"
                : tabname === "Speciality"
                ? "Speciality"
                : ""}
            </Typography>
            <hr/>
            {detailData?.length && detailData?.map((item, index) => (
              <HorizontalHeadingPlusText heading={item?.name} text={item?.value} key={index} />
            ))}
          </Paper>
        </Grid>

        <Grid item xs={12} md={2} className={buttonGrid}>
          <Button variant="outlined" className={button} onClick={()=>handleSavePatientsDetails(tabname, patientId)} >Sync with</Button>
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={rightBox}>
            <Typography variant="h5" align="center" className={header}>
              Practice
            </Typography>
            <Typography align="center" variant="h5" className={header}>
              {tabname === "detail"
                ? "Plan Details"
                : tabname === "coverage"
                ? "Coverage Details"
                : tabname === "DemographicInfo"
                ? "DemographicInfo Details"
                : tabname === "Innetwork"
                ? "In Network"
                : tabname === "Outofnetwork"
                ? "Out of Network"
                : tabname === "Speciality"
                ? "Speciality"
                : ""}
            </Typography>
            <hr />
            <HorizontalHeadingPlusText heading={'Status'} text={patientInsuranceDetailTabData?.status || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Effective Date'} text={patientInsuranceDetailTabData?.effectiveDate || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Expiry Date'} text={patientInsuranceDetailTabData?.expiryDate || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Plan Name'} text={patientInsuranceDetailTabData?.planName || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Policy Type'} text={patientInsuranceDetailTabData?.policyType || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Group Number'} text={patientInsuranceDetailTabData?.groupNumber || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Plan Network ID'} text={patientInsuranceDetailTabData?.planNetworkID || 'N/A'} />
          </Paper>
        </Grid>
      </Grid>
  </div> 
  );
};

export default EligibilityDetailTab;
